export default class MobileScrollHandler {
    private readonly scrollBreak = 120;
    private state = {
        scrolled: false,
    };
    private buttonWrapper: HTMLElement | null;
    private header: HTMLElement;
    private breakpoint: string;

    constructor() {
        this.header = document.querySelector("header") as HTMLElement;
        this.buttonWrapper = this.header.querySelector(".header__inner--cta");
        this.breakpoint = "(min-width: 580px)";
    }

    initProxy = () => {
        const proxy = new Proxy(this.state, {
            set: (target, prop, value) => {
                if (prop === "scrolled") {
                    if (this.state.scrolled !== value) {
                        value ? this.onScrollDown() : this.onScrollUp();
                    }
                }

                target[prop] = value;
                return true;
            },
        });

        this.state = proxy;
    };

    init = async () => {
        this.initProxy();
        window.addEventListener("scroll", this.scrollHandler);
        this.state.scrolled = scrollY > this.scrollBreak;
    };

    scrollHandler = () => {
        this.state.scrolled = scrollY > this.scrollBreak;
    };

    onScrollUp = () => {
        if (!this.buttonWrapper || !this.header) return;

        this.header.classList.remove("scrolled-mobile");
        this.buttonWrapper.classList.add("on-top");

        if (window.matchMedia(this.breakpoint).matches) return;
        this.buttonWrapper.classList.remove("button-hidden");
    };

    onScrollDown = () => {
        if (!this.buttonWrapper || !this.header) return;

        this.header.classList.add("scrolled-mobile");
        this.buttonWrapper.classList.remove("on-top");

        if (window.matchMedia(this.breakpoint).matches) return;
        this.buttonWrapper.classList.add("button-hidden");
    };

    destroy = async () => {
        window.removeEventListener("scroll", this.scrollHandler);
    };
}
