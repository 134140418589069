export class HandleLangSwitcher {
    private languageWrapper: HTMLElement | null;

    constructor() {
        this.languageWrapper = document.querySelector<HTMLElement>("[js-language-switcher]");
    }

    public init = () => {
        if (!this.languageWrapper) return;

        this.changeSwitcherContent();
    };

    private changeSwitcherContent = () => {
        const switcher = this.languageWrapper?.querySelector(".wpml-ls-native") as HTMLElement,
            currentLangPrefix = switcher?.getAttribute("lang")?.toUpperCase() as string;

        if (!switcher || !currentLangPrefix) return;

        switcher.classList.add("switcher-visible");
        switcher.textContent = currentLangPrefix;
    };
}
