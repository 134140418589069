import { openDropDownMenu } from "../../animations/openDropDownMenu";
import { closeDropDownMenu } from "../../animations/closeDropDownMenu";

export let allSubMenus = new Map<HTMLLIElement, SubMenu>();

export default class SubMenu {
    private mainItemSelector = "js-main-nav-button"; // here we check if the element has such an attribute
    private mainItemChildSelector = "ul";
    private childItemSelector = "[js-drop-down-button]";
    private childItemContainClass = "has-children";

    private ulChild: HTMLUListElement | null;
    private items: HTMLLIElement[] | null = null;
    private isOpen: boolean;
    private isMainNav: boolean;
    private submenus: SubMenu[];

    constructor(private root: HTMLLIElement) {
        this.ulChild = root.querySelector(this.mainItemChildSelector);
        this.isMainNav = this.root.hasAttribute(this.mainItemSelector);
        this.isOpen = false;
        this.submenus = [];

        this.addSubmenu();
    }

    public init = async () => {
        this.getSubMenuItem();

        if (!this.items) return;

        this.root?.addEventListener("click", this.handler);
        this.disableDefaultLink(this.root);

        this.items.forEach((item) => {
            this.initializeSubMenu(item);
            this.disableDefaultLink(item);
        });
    };

    public close = () => {
        if (!this.isOpen || !this.ulChild) return;

        closeDropDownMenu(this.ulChild);
        this.isOpen = false;

        this.submenus.forEach((submenu) => submenu.close());
    };

    private initializeSubMenu = (item) => {
        if (item.classList.contains(this.childItemContainClass)) {
            const submenu = new SubMenu(item);
            this.submenus.push(submenu);
            submenu.init();
            item.addEventListener("click", (e: MouseEvent) => e.stopPropagation());
        }
    };

    private getSubMenuItem = () => {
        this.ulChild ? (this.items = [...this.ulChild.children].filter((child: Element) => child.matches(this.childItemSelector)) as HTMLLIElement[]) : (this.items = []);
    };

    private handler = (e: Event) => {
        if (!this.ulChild) return;

        const element = e.currentTarget as HTMLLIElement;

        if (element !== this.root) return;

        if (this.isOpen && this.isMainNav) {
            this.submenus.forEach((submenu) => {
                submenu.close();
            });
        }

        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            if (this.isMainNav) {
                allSubMenus.forEach((submenu) => {
                    if (submenu !== this && submenu.isMainNav) submenu.close();
                });
            }
            openDropDownMenu(this.ulChild);
        } else {
            closeDropDownMenu(this.ulChild);
        }
    };

    private addSubmenu = () => {
        if (!allSubMenus.has(this.root)) {
            allSubMenus.set(this.root, this);
        }
    };

    private disableDefaultLink = (item: HTMLLIElement) => {
        const anchorElement = item.querySelector("a") as HTMLAnchorElement;

        if (anchorElement && anchorElement.getAttribute("href") === "#") {
            anchorElement.addEventListener("click", (e: Event) => e.preventDefault());
        }
    };
}
