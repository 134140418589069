import { gsap } from "gsap";

export const openDropDownMenu = (element: HTMLUListElement) => {
    const parentEl = element.parentNode as HTMLElement;

    const initHeight = element.getBoundingClientRect().height;

    parentEl.classList.add("active-button");
    element.classList.add("active-submenu");

    const finalHeight = element.getBoundingClientRect().height;

    gsap.set(element, { height: initHeight });

    gsap.to(element, {
        height: finalHeight,
        duration: 0.3,
        clearProps: "all",
    });
};
