import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import loadSection from "../sections/loadSection";
import snakeCaseToCamelCase from "../utils/snakeCaseToCamelCase";

class SectionsLoader implements Initializable {
    private readonly sections: HTMLElement[];
    constructor() {
        this.sections = [...document.querySelectorAll<HTMLElement>("main > section")];
    }

    init = async () => {
        this.sections.forEach((section) => {
            this.addIntersectionObserver(section);
        });
    };

    addIntersectionObserver = (section: HTMLElement) => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(async (entry) => {
                    if (!entry.isIntersecting) {
                        return;
                    }
                    const firstClass = [...section.classList].at(0) as string,
                        className = capitalizeFirstLetter(snakeCaseToCamelCase(firstClass));

                    //MIAŁO BYĆ DYNAMICZNE ALE PRZEZ UŁOMNOŚĆ ESBUILDA NIE DA SIĘ NA RAZIE TAK ROBIĆ :sadge:
                    const LoadedClass = await loadSection(className);

                    if (!LoadedClass) {
                        return;
                    }

                    await new LoadedClass(section).init();
                    section.classList.add("initialized");
                    observer.unobserve(section);
                });
            },
            {
                rootMargin: "100px",
            }
        );

        observer.observe(section);
    };
}

export default SectionsLoader;
