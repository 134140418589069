class ScrollHandler implements Initializable {
    initialized = false;
    private readonly scrollBreak = 10;
    private state = {
        scrolled: false,
    };
    private readonly headerBackground: HTMLElement;

    constructor() {
        this.headerBackground = document.querySelector(".header__background") as HTMLElement;
    }

    initProxy = () => {
        const proxy = new Proxy(this.state, {
            set: (target, prop, value) => {
                if (prop === "scrolled") {
                    if (this.state.scrolled !== value) {
                        value ? this.onScrollDown() : this.onScrollUp();
                    }
                }

                target[prop] = value;
                return true;
            },
        });

        this.state = proxy;
    };

    init = async () => {
        this.initProxy();
        window.addEventListener("scroll", this.scrollHandler);
        this.state.scrolled = scrollY > this.scrollBreak;
    };

    scrollHandler = () => {
        this.state.scrolled = scrollY > this.scrollBreak;
    };

    onScrollUp = () => {
        this.headerBackground.classList.remove("scrolled");
    };

    onScrollDown = () => {
        this.headerBackground.classList.add("scrolled");
    };

    destroy = async () => {
        window.removeEventListener("scroll", this.scrollHandler);
    };
}

export default ScrollHandler;
