import { gsap, Power3 } from "gsap";
import { disableScroll } from "src/resources/js/utils/disableScroll";
import { enableScroll } from "src/resources/js/utils/enableScroll";
import { OverlayScrollbars } from "overlayscrollbars";
import "overlayscrollbars/overlayscrollbars.css";
import MobileScrollHandler from "./MobileScrollHandler";
import MenuHandler from "./MenuHandler";

class MobileMenu implements Initializable {
    initialized = false;
    private navigationContainer: HTMLElement | null;
    private toogleButton: HTMLButtonElement | null;
    private navigationElements: HTMLElement[];
    private ctaButton: HTMLAnchorElement | null;
    private tl: GSAPTimeline;
    private breakpoint: string;
    private isOpen: boolean;
    private scrollBars: OverlayScrollbars | null = null;

    private menuClicked: boolean = false;

    constructor(private readonly root: HTMLElement) {
        this.navigationContainer = root.querySelector(".navigation-container");
        this.navigationElements = [...root.querySelectorAll<HTMLElement>(".navigation__item")];
        this.toogleButton = root.querySelector(".header__hamburger--button");
        this.ctaButton = root.querySelector(".header__inner--cta");
        this.breakpoint = "(min-width: 580px)";
        this.isOpen = false;

        this.tl = gsap.timeline({ paused: true });
    }

    public init = async (): Promise<void> => {
        if (!this.navigationContainer || !this.navigationElements) return;

        this.navigationAnimation();
        // this.initializeScrollBars();
        this.listener();

        await new MenuHandler().init();
        await new MobileScrollHandler().init();

        this.watcher();
    };

    public destroy = async (): Promise<void> => {
        gsap.set(this.navigationContainer, { clearProps: "all" });
        gsap.set(this.navigationElements, { clearProps: "all" });

        await new MobileScrollHandler().destroy();

        if (this.ctaButton?.classList.contains("button-hidden")) this.ctaButton?.classList.remove("button-hidden");
        if (this.scrollBars) this.scrollBars.destroy();

        this.toogleButton?.classList.remove("active");
        this.toogleButton?.classList.add("not-active");
        this.ctaButton?.classList.remove("button-open-menu");
    };

    private navigationAnimation = () => {
        this.tl
            .addLabel("start-animation")
            .fromTo(this.navigationContainer, { translateX: "-100%" }, { translateX: 0, duration: 0.5, ease: Power3.easeOut })
            .fromTo(this.navigationElements, { opacity: 0, translateY: "-10px" }, { translateY: 0, opacity: 1, delay: 0.1, stagger: 0.1 }, "start-animation")
            .reverse();
    };

    private handleCtaButton = () => {
        if (!this.ctaButton) return;

        if (this.isOpen) {
            this.ctaButton.classList.remove("button-hidden");
        } else {
            if (!this.ctaButton.classList.contains("on-top")) this.ctaButton.classList.add("button-hidden");
        }
    };

    private hamburgerAnimation = () => {
        this.toogleButton?.classList.toggle("active");
        this.toogleButton?.classList.toggle("not-active");
    };

    private setScrollStatus = () => {
        this.isOpen ? disableScroll() : enableScroll();
    };

    private initializeScrollBars = () => {
        if (!this.navigationContainer) return;

        this.scrollBars = OverlayScrollbars(this.navigationContainer, {
            overflow: {
                x: "hidden",
            },
        });
    };

    private listener = () => {
        this.toogleButton?.addEventListener("click", () => {
            this.isOpen = !this.isOpen;

            this.isOpen && !window.matchMedia(this.breakpoint).matches ? this.ctaButton?.classList.add("button-open-menu") : this.ctaButton?.classList.remove("button-open-menu");

            this.hamburgerAnimation();
            this.setScrollStatus();
            if (!window.matchMedia(this.breakpoint).matches) this.handleCtaButton();

            this.tl.reversed(!this.tl.reversed());
        });
    };

    private watcher = () => {
        window.matchMedia(this.breakpoint).addEventListener("change", (e) => {
            if (e.matches) {
                this.ctaButton?.classList.remove("button-hidden");
            } else if (!this.ctaButton?.classList.contains("on-top") && !this.menuClicked) {
                this.ctaButton?.classList.add("button-hidden");
            }
        });
    };
}

export default MobileMenu;
