export const disableScroll = () => {
    document.body.style.overflowY = "hidden";
    document.body.classList.add("scroll-locked");
    var x = window.scrollX;
    var y = window.scrollY;
    window.scrollTo(x, y);
    window.onscroll = function () {
        window.scrollTo(x, y);
    };
};
