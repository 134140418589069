const loadSection = async (section: string) => {
    //tutaj trzeba dodać case do każdej sekcji, która będzie miała jakieś funkcjonalności, żeby ładowało ją dynamicznie w zależności czy jest taka potrzeba
    switch (section) {
        case "SolutionsTree":
            const { default: SolutionsTree } = await import(`./SolutionsTree`);
            return SolutionsTree;
        case "CustomersSlider":
            const { default: CustomersSlider } = await import(`./CustomersSlider`);
            return CustomersSlider;
        case "TabsContent":
            const { default: TabsContent } = await import(`./TabsContent`);
            return TabsContent;
        case "InteractiveLaptop":
            const { default: InteractiveLaptop } = await import(`./InteractiveLaptop`);
            return InteractiveLaptop;
        case "TwoColsSlider":
            const { default: TwoColsSlider } = await import(`./TwoColsSlider`);
            return TwoColsSlider;
        case "TwoColsCustomers":
            const { default: TwoColsCustomers } = await import(`./TwoColsCustomers`);
            return TwoColsCustomers;
        case "BlogPage":
            const { default: BlogPage } = await import(`./BlogPage`);
            return BlogPage;
        case "CustomersCols":
            const { default: CustomersCols } = await import(`./CustomersCols`);
            return CustomersCols;
        case "TwoColsOptions":
            const { default: TwoColsOptions } = await import(`./TwoColsOptions`);
            return TwoColsOptions;
        case "CalculatorSection":
            const { default: CalculatorSection } = await import(`./CalculatorSection`);
            return CalculatorSection;
        case "FaqSection":
            const { default: FaqSection } = await import(`./FaqSection`);
            return FaqSection;
    }
};

export default loadSection;
