import { computePosition, flip, offset } from "@floating-ui/dom";
import gsap from "gsap";

export default class TopSubmenuItem {
    constructor(private readonly ref: HTMLElement, private readonly floating: HTMLUListElement) {}

    public show = () => {
        gsap.to(this.floating, {
            autoAlpha: 1,
            pointerEvents: "auto",
            duration: 0.2,
            translateY: "0",
            zIndex: 100,
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            onStart: () => {
                this.update();
            },
        });
    };

    public hide = () => {
        gsap.to(this.floating, { autoAlpha: 0, pointerEvents: "none", duration: 0.2, translateY: "-0.5rem", zIndex: 10, boxShadow: "rgba(0, 0, 0, 0) 0px 1px 3px 0px, rgba(0, 0, 0, 0) 0px 1px 2px 0px" });
    };

    private update = () => {
        computePosition(this.ref, this.floating, {
            placement: "bottom-start",
            middleware: [
                flip(),
                offset({
                    crossAxis: 8,
                }),
            ],
        }).then(({ x, y }) => {
            Object.assign(this.floating.style, {
                top: `${y}px`,
                left: `${x}px`,
            });
        });
    };
}
