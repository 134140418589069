import SlimSelect from "slim-select";

export default class CustomSelect {
    private selects: HTMLElement[];
    private setDefault: boolean | null;

    constructor(private readonly root: HTMLElement, setDefault: boolean = true) {
        this.selects = [...this.root.querySelectorAll<HTMLElement>(".custom-select")];
        this.setDefault = setDefault;
        this.init();
    }

    public init = async () => {
        this.initializeSelect();
    };

    private initializeSelect = () => {
        this.selects.forEach((select) => {
            this.setDefault && this.setDefaultOption(select as HTMLSelectElement);

            new SlimSelect({
                select: select,
                settings: {
                    showSearch: false,
                },
            });
        });
    };

    public setDefaultOption = (select: HTMLSelectElement) => {
        if (!select) return;

        const option = [...select.querySelectorAll<HTMLOptionElement>("option")];
        const defaultOption = option[0];

        defaultOption.selected = true;
        defaultOption.setAttribute("data-placeholder", "true");
        defaultOption.value = "";
    };
}
