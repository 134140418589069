import ScrollHandler from "./ScrollHandler";
import TopSubmenuItem from "./TopSubmenuItem";
import LowerSubmenuItemsHandler from "./LowerSubmenuItemsHandler";

export default class DesktopMenu {
    private itemsWithChildren: HTMLLIElement[];
    private scrollHandler: ScrollHandler;

    private topLevelSubmenu: TopSubmenuItem | null = null;
    private lowerLevelSubmenu: LowerSubmenuItemsHandler | null = null;

    constructor(private readonly root: HTMLElement) {
        this.itemsWithChildren = [...this.root.querySelectorAll<HTMLLIElement>(".has-children[js-main-nav-button]")];
        this.scrollHandler = new ScrollHandler();
    }

    public init = async () => {
        if (!this.itemsWithChildren) return;

        await this.scrollHandler.init();
        this.listeners();
    };

    public destroy = async () => {
        this.scrollHandler.destroy();
        this.lowerLevelSubmenu?.destroy();
        this.topLevelSubmenu?.hide();

        this.topLevelSubmenu = null;
        this.lowerLevelSubmenu = null;
    };

    private updateTopSubmenusInstance = async (propName: string, ref) => {
        if (this[propName]) this[propName] = null;

        this[propName] = ref;
    };

    private openSubmenuHandler = ({ currentTarget }: MouseEvent) => {
        const currentNavItem = currentTarget as HTMLLIElement,
            topSubmenu = currentNavItem.querySelector(".submenu") as HTMLUListElement;

        this.updateTopSubmenusInstance("topLevelSubmenu", new TopSubmenuItem(currentNavItem, topSubmenu));
        this.updateTopSubmenusInstance("lowerLevelSubmenu", new LowerSubmenuItemsHandler(currentNavItem));

        if (this.topLevelSubmenu) this.topLevelSubmenu.show();

        this.lowerLevelSubmenu?.init();
    };

    private closeSubmenuHandler = () => {
        if (this.topLevelSubmenu) this.topLevelSubmenu.hide();
        if (this.lowerLevelSubmenu) this.lowerLevelSubmenu.destroy();
    };

    private listeners = () => {
        this.itemsWithChildren.forEach((item) => {
            item.addEventListener("mouseenter", this.openSubmenuHandler);
            item.addEventListener("mouseleave", this.closeSubmenuHandler);
        });
    };
}
