import { LowerSubmenuItem, lowerSubmenuItems } from "./partials/LowerSubmenuItem";

export default class LowerSubmenuItemsHandler {
    private itemsWithChildren: HTMLElement[];

    constructor(private readonly root: HTMLElement) {
        this.itemsWithChildren = [...this.root.querySelectorAll<HTMLElement>(".has-children[js-drop-down-button]")];
    }

    public init = async () => {
        this.itemsWithChildren.forEach((item) => {
            new LowerSubmenuItem(item).init();
        });
    };

    public destroy = async () => {
        this.destroyAllLowerSubmenus();
    };

    private destroyAllLowerSubmenus = () => {
        if (lowerSubmenuItems.size === 0) return;

        lowerSubmenuItems.forEach((item) => {
            item.destroy();
        });
    };
}
