export default class FormHandler {
    private selects: HTMLSelectElement[];
    constructor(private readonly root: HTMLFormElement) {
        this.selects = [...this.root.querySelectorAll<HTMLSelectElement>("select")];
    }

    public init = async () => {
        if (!this.root || this.selects.length == 0) return;

        this.listeners();
    };

    private handler = () => {
        this.selects.forEach((select) => {
            const option = [...select.querySelectorAll<HTMLOptionElement>("option")];
            const defaultOption = option[0];

            defaultOption.selected = true;
            defaultOption.value = "";
        });
    };

    private listeners = () => {
        this.root.addEventListener("wpcf7mailsent", this.handler);
    };
}
