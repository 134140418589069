import { gsap } from "gsap";

export const closeDropDownMenu = (element: HTMLUListElement) => {
    const parentEl = element.parentNode as HTMLElement;

    const initHeight = element.getBoundingClientRect().height;

    parentEl.classList.remove("active-button");
    element.classList.remove("active-submenu");

    const finalHeight = element.getBoundingClientRect().height;

    gsap.set(element, { height: initHeight });

    gsap.to(element, {
        height: finalHeight,
        duration: 0.3,
        onComplete: () => {
            gsap.set(element, { clearProps: "all" });
        },
    });
};
