import { enableScroll } from "../../utils/enableScroll";
import { disableScroll } from "../../utils/disableScroll";

export class Modal {
    private trigger: HTMLElement | null;
    private closeButton: HTMLElement | null;
    private overlay: HTMLElement | null;

    constructor(private root: HTMLElement) {
        this.trigger = this.root.querySelector<HTMLElement>("#trigger");
        this.closeButton = this.root.querySelector<HTMLButtonElement>("[js-close-modal]");
        this.overlay = this.root.querySelector<HTMLElement>("[js-modal-overlay]");
    }

    public init = () => {
        if (!this.root) return;

        this.listeners();
    };

    private open = () => {
        this.root?.classList.add("open");
        disableScroll();
    };

    private close = () => {
        this.root?.classList.remove("open");
        enableScroll();
    };

    private closeByKey = (e: KeyboardEvent) => {
        if (e.key === "Escape") this.close();
    };

    private listeners = () => {
        this.trigger?.addEventListener("click", this.open);
        this.closeButton?.addEventListener("click", this.close);
        this.overlay?.addEventListener("click", this.close);
        document.addEventListener("keydown", this.closeByKey);
    };
}
