import Counter from "./components/Counter";
import Lazy from "vanilla-lazyload";
import Menu from "./components/Menu";
import SectionsLoader from "./components/SectionsLoader";
import CustomSelect from "./components/CustomSelect";
import ShowMoreText from "./components/ShowMoreText";
import FormHandler from "./components/FormHandler";
import { Modal } from "./sections/components/Modal";
import { HandleLangSwitcher } from "./components/HandleLangSwitcher";

require("fslightbox");

window.addEventListener("DOMContentLoaded", async () => {
    handleLazyImages();
    await new ShowMoreText();

    await handleMenu();
    await handleSectionsLoader();
    await handleCounters();
    await handleVideos();
    await handleCustomSelect();
    await handleScrollToSection();
    await handleFormSend();
    await handleModals();
    await handleLanguageSwitcher();

    document.addEventListener("fetched-data", () => {
        console.log("fetched");
        handleLazyImages();
    });
});

const handleLanguageSwitcher = async () => {
    await new HandleLangSwitcher().init();
};

const handleLazyImages = () => {
    new Lazy({
        class_entered: "lazy",
    });
};

const handleMenu = async () => {
    await new Menu().init();
};

const handleCounters = async () => {
    await new Counter().init();
};

const handleVideos = async () => {
    const videos = [...document.querySelectorAll<HTMLVideoElement>(".video")];

    if (videos.length === 0) return;

    const { default: Videos } = await import("./components/Videos");
    await new Videos(videos).init();
};

const handleSectionsLoader = async () => {
    await new SectionsLoader().init();
};

const handleCustomSelect = async () => {
    const wrappers = document.querySelectorAll<HTMLElement>(".contact-box");

    if (!wrappers) return;

    wrappers.forEach((wrapper) => {
        new CustomSelect(wrapper);
    });
};

const handleScrollToSection = async (): Promise<void> => {
    const links = [...document.querySelectorAll<HTMLAnchorElement>("a[href^='#']")];

    if (links.length === 0) return;

    links.forEach((link) => {
        link.addEventListener("click", (e) => {
            e.preventDefault();

            const href = link.getAttribute("href") as string;
            if (!href) return;

            const section = document.querySelector(href) as HTMLElement;
            if (!section) return;

            const header = document.querySelector(".header") as HTMLElement;
            if (!header) return;

            const headerHeight = header.offsetHeight as number;

            window.scrollTo({
                top: section.offsetTop - headerHeight,
                behavior: "smooth",
            });
        });
    });
};

const handleFormSend = async () => {
    const forms = document.querySelectorAll("form");

    if (forms.length == 0) return;

    forms.forEach((form) => {
        new FormHandler(form).init();
    });
};

const handleModals = async () => {
    const modals = document.querySelectorAll<HTMLElement>(".global-modal");

    modals.forEach((modal) => {
        new Modal(modal).init();
    });
};
