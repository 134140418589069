import SubMenu, { allSubMenus } from "./SubMenu";

export default class MenuHandler {
    private itemsSelector = "[js-main-nav-button]";
    private itemContainClass = "has-children";
    private hamburgerButtonSelector = ".header__hamburger--button";

    private navItems: HTMLElement[];

    constructor() {
        this.navItems = [...document.querySelectorAll<HTMLLIElement>(this.itemsSelector)];
    }
    public init = async () => {
        if (!this.navItems) return;

        this.addHamburgerEventListener();

        this.navItems.forEach((item) => {
            if (item.classList.contains(this.itemContainClass)) new SubMenu(item as HTMLLIElement).init();
        });
    };

    private addHamburgerEventListener = () => {
        const hamburgerButton = document.querySelector(this.hamburgerButtonSelector);
        if (hamburgerButton) {
            hamburgerButton.addEventListener("click", () => {
                allSubMenus.forEach((submenu) => submenu.close());
            });
        }
    };
}
