import { computePosition, flip, offset } from "@floating-ui/dom";
import gsap from "gsap";

export const lowerSubmenuItems = new Map<HTMLElement, LowerSubmenuItem>();

export class LowerSubmenuItem {
    private arrow: HTMLButtonElement | null = null;
    private ref: HTMLElement | null = null;
    private floating: HTMLElement | null = null;

    public showTimeout: ReturnType<typeof setTimeout> | null = null;
    public hideTimeout: ReturnType<typeof setTimeout> | null = null;

    constructor(private readonly root: HTMLElement) {}

    public init = async () => {
        this.listeners();
        this.addSubMenus();
    };

    public destroy = async () => {
        this.removeListeners();

        if (this.showTimeout) {
            clearTimeout(this.showTimeout);
            this.showTimeout = null;
        }

        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = null;
        }
    };

    private show = (item: HTMLLIElement) => {
        this.getSubmenuElements(item);

        gsap.to(this.floating, {
            autoAlpha: 1,
            pointerEvents: "auto",
            duration: 0.3,
            left: "100%",
            zIndex: "-1",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            onStart: () => {
                this.update();
            },
        });

        gsap.to(this.arrow, { rotate: "-90deg", duration: 0.1 });
    };

    private hide = () => {
        if (!this.floating) return;
        gsap.to(this.floating, { autoAlpha: 0, pointerEvents: "none", duration: 0.3, left: "0", boxShadow: "rgba(0, 0, 0, 0) 0px 1px 3px 0px, rgba(0, 0, 0, 0) 0px 1px 2px 0px" });

        gsap.to(this.arrow, { rotate: "0", duration: 0.1 });
    };

    private update = () => {
        computePosition(this.root, this.floating as HTMLElement, {
            placement: "right-start",
            middleware: [
                flip(),
                offset({
                    crossAxis: 8,
                }),
            ],
        }).then(({ x, y }) => {
            Object.assign(this.floating!.style, {
                left: `${x}px`,
            });
        });
    };

    private showHandler = ({ currentTarget }: MouseEvent) => {
        const item = currentTarget as HTMLLIElement;

        if (this.hideTimeout) {
            this.clearAllSubmenusTimeout();
            clearTimeout(this.hideTimeout);
            this.hideTimeout = null;
        }

        this.showTimeout = setTimeout(() => {
            this.show(item);
        }, 50);
    };

    private hideHandler = () => {
        if (this.showTimeout) {
            clearTimeout(this.showTimeout);
            this.showTimeout = null;
        }

        this.hideTimeout = setTimeout(() => {
            this.hide();
        }, 500);
    };

    private clearAllSubmenusTimeout = () => {
        if (lowerSubmenuItems.size === 0) return;

        lowerSubmenuItems.forEach(async (item) => {
            if (item.root !== this.root) {
                item.hide();
                if (item.hideTimeout) {
                    clearTimeout(item.hideTimeout);
                    item.hideTimeout;
                }
            }
        });
    };

    private getSubmenuElements = (item) => {
        this.ref = item as HTMLElement;
        this.floating = this.ref.querySelector("[js-submenu]") as HTMLUListElement;
        this.arrow = this.ref.querySelector(".submenu__more");
    };

    private removeListeners = () => {
        this.root.removeEventListener("mouseenter", this.showHandler);
        this.root.removeEventListener("mouseleave", this.hideHandler);
    };

    private listeners = () => {
        this.root.addEventListener("mouseenter", this.showHandler);
        this.root.addEventListener("mouseleave", this.hideHandler);
    };

    private addSubMenus = () => {
        if (!lowerSubmenuItems.has(this.root)) {
            lowerSubmenuItems.set(this.root, this);
        }
    };
}
