import DesktopMenu from "./menu/desktop/DesktopMenu";
import MobileMenu from "./menu/mobile/MobileMenu";

class Menu {
    private root: HTMLElement;
    private currentType: "desktop" | "mobile";
    private currentMenu: DesktopMenu | MobileMenu | null = null;
    private breakpoint: string;

    constructor() {
        this.root = document.querySelector("header") as HTMLElement;
        this.currentType = innerWidth > 1279 ? "desktop" : "mobile";
        this.breakpoint = "(min-width: 1280px)";
    }

    public init = async () => {
        this.currentType === "desktop" ? await this.handleDesktop() : await this.handleMobile();
        this.watcher();
    };

    private watcher = () => {
        const mediaQuery = window.matchMedia(this.breakpoint);

        mediaQuery.addEventListener("change", async (e) => {
            console.log(this.currentMenu);
            await this.currentMenu?.destroy();
            this.root.replaceWith(this.root.cloneNode(true));
            this.root = document.querySelector("header") as HTMLElement;
            e.matches ? await this.handleDesktop() : await this.handleMobile();
        });
    };

    private handleDesktop = async () => {
        this.currentType = "desktop";
        const menu = new DesktopMenu(this.root.querySelector<HTMLElement>(".navigation-container") as HTMLElement);
        this.currentMenu = menu;
        await menu.init();
    };

    private handleMobile = async () => {
        this.currentType = "mobile";
        const menu = new MobileMenu(this.root);
        this.currentMenu = menu;
        await menu.init();
    };
}

export default Menu;
