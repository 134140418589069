import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

class Counter implements Initializable {
  private readonly toCount: HTMLElement[];

  constructor() {
    this.toCount = [...document.querySelectorAll<HTMLElement>("[data-countable]")];
  }

  init = async () => {
    this.toCount.forEach((item) => {
      gsap.from(item, {
        textContent: "0",
        duration: 1,
        ease: "power1.inOut",
        modifiers: {
          textContent: (value) => Number(value).toFixed(0),
        },
        scrollTrigger: {
          trigger: "[data-countable]",
          start: "-200px 80%",
          end: "+=100",
          toggleActions: "play none none reverse",
        },
      });
    });
  };
}

export default Counter;
