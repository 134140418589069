export default class ShowMoreText {
    private showButton: HTMLButtonElement[] | null;

    constructor() {
        this.showButton = [...document.querySelectorAll<HTMLButtonElement>("[js-text-limit-btn]")];
        this.init();
    }

    public init = async (): Promise<void> => {
        if (!this.showButton) return;

        this.showButton?.forEach((button) => {
            button.addEventListener("click", this.switchTextVisibleLength);
        });
    };

    private switchTextVisibleLength = (e): void => {
        e.preventDefault();

        const textWrapper = e.currentTarget.closest(".checkbox-wrapper");

        textWrapper.classList.toggle("show-more-text");

        e.currentTarget.classList.toggle("active-arrow");
    };
}
